<template>
  <div>
    <categoryCard
      :content="content"
      :percentProgress="progress"
      :absoluteProgress="watchedTime"
      :displayTags="false"
      :playerInstance="playerInstance"
      @remove="removeFromFire"
      :isDirectPlay="content.objecttype === 'CONTENT' ? true : false"
      :cardType="cardDisplayType"
      :showRemove="showRemove"
      :showTitle="true"
      :isContinueWatch="isContinueWatch"
    ></categoryCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Common from "@/mixins/common.js";
import { store } from "@/store/store";
import Utility from "@/mixins/Utility.js";
import firebase from "firebase/app";
import "firebase/database";

export default {
  props: {
    screen: {
      type: Object,
    },
    content: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
    isContinueWatch: {
      type: Boolean,
    },
  },
  data() {
    return {
      progress: null,
      watchedTime: null,
      availabilities: [],
      subscriptions: [],
      purchases: [],
      filteredAvailabilities: [],
      pricemodel: [],
      showTitle: true,
      showRemove: true,
      cardDisplayType: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid", "availabilityList", "subscriptionList", "purchaseList"]),
  },
  watch: {
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
  },
  created() {
    // console.log("screen", this.screen);

    this.fetchCardType();
    // console.log("content from content progress", this.content);
    eventBus.$on("re-render-carousel", () => {
      return this.fetchCardType();
    });
    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }
  },
  mounted() {
    this.getContentProgress();
    this.getEpisodeContentProgress();
    eventBus.$on(`contentDetail-response-${this.content.objectid}`, (response) => {
      console.log("contentDetail-response", response.data);
      if (!response.data.reason) {
        let currentContent = response.data;
        currentContent.watchedTime = this.watchedTime;

        //check availability before playing.
        this.checkAvailability(currentContent);
      }
    });
  },
  methods: {
    fetchCardType() {
     if (screen.width <= 425) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "100px",
          width: "160px",
          playButton: { enablePlay: true, width: "40px", height: "40px" }
        };
      } else if (screen.width > 425 && screen.width < 800) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "147px",
          width: "240px",
          playButton: { enablePlay: true, width: "40px", height: "40px" }
        };
      } else if (screen.width > 800 && screen.width < 1050) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "120px",
          width: "210px",
          playButton: { enablePlay: true, width: "40px", height: "40px" },
        };
      } else if (screen.width > 1200 && screen.width < 1350) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "140px",
          width: "270px",
          playButton: { enablePlay: true, width: "40px", height: "40px" },
        };
      } else {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "150px",
          width: "260px",
          playButton: { enablePlay: true, width: "40px", height: "40px" },
        };
      }
    },
    getContentProgress() {
      let content = this.content;
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          if (content && response) {
            if (response.status === "INPROGRESS") {
              if (response.objecttype === "CONTENT") {
                let duration = response.duration;
                let watched = response.watchedduration;
                this.watchedTime = response.watchedduration;
                let progress = (watched / duration) * 100;
                if (progress > 0 && progress < 100) {
                  this.progress = progress;
                }
              }
            }
          }
        });
    },
    getEpisodeContentProgress() {
      let content = this.content;
      if (content.status === "INPROGRESS") {
        let duration = content.duration;
        let watched = content.watchedduration;
        this.watchedTime = content.watchedduration;
        let progress = (watched / duration) * 100;
        if (progress > 0 && progress < 100) {
          this.progress = progress;
        }
      }
    },
    playContent(content) {
      this.$emit("playContent", content);
    },
    removeFromFire(content) {
      this.$emit("removeFromContinueData", content);
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "genreDrop" */ "@/components/Templates/categoryCard.vue"),
  },
  beforeDestroy() {
    eventBus.$off("contentDetail-response");
  },
  mixins: [Common, Utility],
};
</script>

<style lang="scss" scoped></style>
